import React from "react";

export default function PreNav(props) {
  const toggleNavMenu = () => {
    let modal = document.getElementById("navbar");
    let navMenuButton = document.getElementById("navMenuButton");

    modal.classList.add("width100");
    navMenuButton.classList.add("hidden");
  };
  return (
    <div className="pre-nav">
      
      <button
        id="navMenuButton"
        onClick={toggleNavMenu}
        className="navMenuButton"
       
      >
        
        <img src="https://www.cssscript.com/demo/fullscreen-hamburger-mega-menu/images/burger-menu.svg"></img> 
        Menu
      </button>
    </div>
  );
}
