import React from "react";

export default function AboutMe(props) {
  console.log(props);
  function createContent(selection, pageMod = "") {
    let lang = props.language;
    let classNameSecondary = "secondary" + pageMod;
    let classNameTertiary = "tertiary" + pageMod;
    let item = selection.map((primary) => (
      <div key={primary.id} className={classNameSecondary}>
        <h2>{primary[lang].title}</h2>
        <div className={classNameTertiary}>{primary[lang].content}</div>
      </div>
    ));
    return item;
  }
  // cambiar acerca de mi por "mí"
  let selection = [
    {
      EN: {
        title: "Santiago Amerio",
        content: (
          <div>
            <p>
              Im a 28 years old full stack developer, pc builder, OS
              troubleshooter and network manager.
            </p>
            <p>
              {" "}
              I am a technology enthusiast. Always interested on new
              technologies and it workings.
            </p>
          </div>
        ),
      },
      ES: {
        title: "Santiago Amerio",
        content: (
          <div>
            <p>
              Soy un desarrollador full stack, network manager de 28 años,
              tambien armo y reparo computadoras.
            </p>
            <p>
              Soy un entusiasta de la tecnología.Siempre interesado en aprender
              sobre nuevas tecnologías.
            </p>
          </div>
        ),
      },
      id: 0,
    },
    {
      EN: {
        title: "Programming",
        content: (
          <p>
            four years ago I start programming static pages with PHP. One of my
            projects was a webpage that showed work schedules for my co-workers.
            because the company only give an android version of the application.
            This was what started the project{" "}
            <a target="_blank" href="https://asignaciones.com.ar">
              asignaciones.com.ar
            </a>{" "}
            <button
              className="goToProject"
              onClick={() => props.scrollTo("asignaciones")}
            >
              Go to project
            </button>{" "}
            A webpage where my co-workers can look at they work schedules even
            if they don't fulfill the requirements to install the company app.
            On this SPA I implemented SQL databases, PHP back end, react
            framework and Sass/css
          </p>
        ),
      },
      ES: {
        title: "Programación",
        content: (
          <p>
            Hace cuatro años empecé a programar, empezando por PHP y páginas
            estáticas. Uno de mis proyectos fue una página web que mostraba los
            horarios de trabajo para mis compañeros que no podían descargar la
            aplicación, ya que esta solo estaba en Android. Así comencé lo que
            hoy es{" "}
            <a target="_blank" href="https://asignaciones.com.ar">
              asignaciones.com.ar
            </a>
            <button
              className="goToProject"
              onClick={() => props.scrollTo("asignaciones")}
            >
              Ir al proyecto
            </button>{" "}
            una página donde los empleados pueden ver sus horarios aunque no
            cumplan los requisitos para instalar la app dada por la empresa.
          </p>
        ),
      },
      id: 1,
    },
    {
      id: 2,
      EN: {
        title: "IT",
        content: (
          <p>
            I work as a freelancer on computer service since 2018, mainly with
            Windows personal computers. formatting drives, installing new
            components and repairing both desktop and laptop. <br /> I have used
            linux in a form or another since I was 15 years old but in my
            twenties I make the jump from windows to linux for 1 year, then I
            went back to windows. since then I have been switching between linux
            and windows.
            <button
              className="goToProject"
              onClick={() => props.scrollTo("blackbox")}
            >
              Go to project
            </button>
          </p>
        ),
      },
      ES: {
        title: "IT",
        content: (
          <p>
            Trabajo como freelancer desde el 2018, mayormente con computadoras
            personales corriendo Windows, formateando discos, instalando nuevos
            componentes y reparando computadoras de escritorio y laptop. <br />{" "}
            Uso Linux en computadoras desde los 15 años, pero en mis 20 di el
            salto de Windows a Linux por un año y empece a usar servidores
            linux, después volví a Windows y desde ese momento estuve yendo y
            viniendo entre Windows y Linux.
            <button
              className="goToProject"
              onClick={() => props.scrollTo("blackbox")}
            >
              Ir al proyecto
            </button>
          </p>
        ),
      },
    },
    {
      id: 3,
      EN: {
        title: "Electronics",
        content: (
          <div>
            <p>
              I have some some experience repairing electronics, replacing
              broken components following manufacturers schematics.
            </p>
            <p>
              I also have experience working with arduino, one of the mayor
              projects I have created it is a interior lighting/watering system
              for carnivorous plants growing.
              <button
                className="goToProject"
                onClick={() => props.scrollTo("redBookshelf")}
              >
                Go to project
              </button>
              .
            </p>
          </div>
        ),
      },
      ES: {
        title: "Electrónica",
        content: (
          <div>
            <p>
              Hace poco tiempo comencé a reparar aparatos de uso diario
              reemplazando componentes con alguna falla siguiendo esquemas
              proporcionados por los fabricantes de estos mismos.
            </p>

            <p>
              Tambien tengo experiencia con arduino, uno de los proyectos que
              cree un sistema encargado de manejar luces y bombas de agua para
              cultivo en interiores de plantas carnívoras.
              <button
                className="goToProject"
                onClick={() => props.scrollTo("redbookshelf")}
              >
                Ir al proyecto
              </button>
            </p>
          </div>
        ),
      },
    },
  ];
  return (
    <div id="about-me" className="content">
      <div className="title noselect">
        <div className="title-container">{props.createNav("aboutme")}</div>
      </div>
      <div className="primary-about">{createContent(selection, "-about")}</div>
    </div>
  );
}
