import React from "react";

export default function WebDev(props) {
  let selection = [
    {
      ES: {
        title: "PHP",
        content: [
          "Sistemas de registro e inicio de sesión.",
          "Autenticación de usuarios.",
          "Scripts encargados de trabajar con tablas en base de datos.",
          "Apis encargadas de comunicar entre el front end y base de datos.",
        ],
      },
      EN: {
        title: "PHP",
        content: [
          "Login and signin systems.",
          "User authentication.",
          "Database handling via scrips",
          "Frontend backend communication api",
        ],
      },
      id: 0,
    },
    {
      ES: {
        title: "Javascript",
        content: [
          "Creación de páginas web dinámicas sin framework (SPA & MPA).",
          "Utilización de React como framework para desarrollo de webapp (SPA & MPA).",
        ],
      },
      EN: {
        title: "Javascript",
        content: [
          "Frontend development without framework (SPA & MPA)",
          "Frontend development with React framework (SPA & MPA)",
        ],
      },
      id: 1,
    },
    {
      ES: {
        title: "CSS",
        content: [
          "Diseño con frameworks como Bootstrap y Bulma.",
          "Diseño manual con Css/Sass.",
        ],
      },
      EN: {
        title: "CSS",
        content: [
          "Web design via framework like bulma and bootstrap",
          "Manual design via CSS/Sass",
        ],
      },
      id: 2,
    },

    {
      ES: {
        title: "Base de datos SQL",
        content: ["Creación y edición de base de datos SQL."],
      },
      EN: {
        title: "SQL database",
        content: ["SQL databases creation and modification."],
      },
      id: 3,
    },
  ];

  
  return (
    <div id="webDev" className="content">
      <div className="title">
        <div className="title-container">
        {props.createNav("webdev")}
        </div>
      </div>
      <div className="primary"><div className="cont">{props.createContent(selection)}</div></div>
      <div className="under"></div>
    </div>
  );
}
