import Start from "./components/Start";
import Downloads from "./components/Downloads";
import Blog from "./components/blog/Inicio";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

function App() {
 

  return (
    <div className="App">
      
      <div className="welcome">
        <Router>
          <Switch>
            <Route path="/">
            <div className="background"></div>
              <Start />
            </Route>
          </Switch>
          <Switch>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/downloads">
              <Downloads />
            </Route>
            
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
