import React from "react";

export default function BlackBox(props) {
  let translation = [
    <div className="secondary">
      <h2>Servidor local BlackBox</h2>
      <div className="tertiary">
        <h3>Servidor domestico.</h3>
        <br />
        <p>
          BlackBox fue construido para que en mi casa tengamos un sistema de
          backup centralizado.
        </p>
        <p>
          Utilizacion:
        </p>
        <ul>
            <li>OpenMediaVault</li>
            <li>Docker</li>
            <li>Nginx - webserver + proxy manager</li>
            <li>Sistema de backups</li>
          </ul>
      </div>
    </div>,
    <div className="secondary">
      <h2>BlackBox local server</h2>
      <div className="tertiary">
        <h3>
         Home server.
        </h3>

        <p>
          BlackBox was build so that in my house we have a local storage
          centralized backup.
        </p>
        <p>
          Uses:
        </p>
        <ul>
            <li>OpenMediaVault</li>
            <li>Docker</li>
            <li>Nginx - webserver + proxy manager</li>
            <li>Sistema de backups</li>
          </ul>
      </div>
    </div>,
  ];
  let display;

  if (props.language === "EN") {
    display = translation[1];
  } else {
    display = translation[0];
  }
  return <div id="blackbox">{display}</div>;
}
