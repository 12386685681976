import React from "react";
import Asignaciones from "./projects/Asignaciones";
import BlackBox from "./projects/BlackBox";
import RedBookshelf from "./projects/RedBookshelf";
export default function Projects(props) {
  
  return (
    <div id="projects" className="content">
      <div className="title">
        <div className="title-container">
          {props.createNav("projects")}
        </div>
      </div>
      <div className="primary-proj">
        <div className="cont-proj">
          <Asignaciones {...props} />
          <BlackBox {...props}/>
          <RedBookshelf {...props}/>
        </div>
      </div>
      <div className="under"></div>
    </div>
  );
}
