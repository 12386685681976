function It(props) {
   let selection = [
    {
      ES: {
        title: "Linux",
        content: [
          "Instalación y administración de sistemas Linux mediante consola e interfaz de usuario (Debian y distros basadas en Debian).",
          "Despliegue de servidores Linux, para soluciones hogareñas (NAS servers, Openmediavault, TrueNAS).",
          "Despliegue de aplicaciones mediante Docker.",
        ],
      },
      EN: {
        title: "Linux",
        content: [
          "Linux systems installation via terminal and GUI (Debian and distros based on Debian).",
          "Linux server installation and management.",
          "Linux server deployment for home users (NAS servers, Openmediavault, TrueNAS)",
          "Docker containers deployment",
        ],
      },
      id: 0,
    },
    {
      ES: {
        title: "Windows",
        content: [
          "Instalación y administración del sistema, búsqueda de problemas y reparación.",
        ],
      },
      EN: {
        title: "Windows",
        content: [
          "Windows installation and administration, troubleshooting and repair.",
        ],
      },
      id: 1,
    },
    {
      ES: {
        title: "Administración",
        content: [
          "Configuración de servidores tipo NAS (Openmediavault).",
          "Instalación de servidores Linux.",
          "Configuración de redes hogareñas y de mediana empresa.",
          "Configuración de redes simples y Vlans (router, switch, AP)."
        ],
      },
      EN: {
        title: "Adminitration",
        content: [
          "Openmediavault type servers configuration.",
          "Linux server installation on hardware.",
          "Home and small business network configuration",
          "switch, router, ap deployment and configuration",
        ],
      },
      id: 2,
    },
    {
      ES: {
        title: "Cableado y conexiones",
        content: ["Crimpeado de conectores rj45."],
      },
      EN: {
        title: "Cabling and connections",
        content: ["rj45 crimping."],
      },
      id: 3,
    },
  ];

  return (
    <div id="it" className="content">
      <div className="title">
        <div className="title-container">
        {props.createNav("it")}
        </div>
      </div>
      <div className="primary"><div className="cont">{props.createContent(selection)}</div></div>
      <div className="under"></div>
    </div>
  );
}
export default It;
