import React from "react";
import Footer from "./Footer";
import PreNav from "../subcomponents/PreNav";
import BlogList from "./BlogList";
export default function Inicio() {
  return (
      <div>
          <div className="background blog-background"></div>
    <div className="blog-container">
      <Footer style="dark" />
      <div className="pre-nav blog-prenav"></div>
      <BlogList />
    </div>
    </div>
  );
}
