import { useState } from "react";

import It from "./It";
import NavBar from "./NavBar";
import Electronics from "./subcomponents/Electronics";
import PreNav from "./subcomponents/PreNav";
import WebDev from "./WebDev";
import AboutMe from "./AboutMe";
import Projects from "./Projects";
import Footer from "./Footer";
console.log(window.location.href)
const Start = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const [language, setLanguage] = useState("ES");

  function createContent(selection, pageMod = "") {
    let lang = language;
    let countint = 0;
    let count = () => {
      countint++;
      return countint;
    };
    let classNameSecondary = "secondary" + pageMod;
    let classNameTertiary = "tertiary" + pageMod;
    let item = selection.map((primary) => (
      <div key={primary.id} className={classNameSecondary}>
        <h2>{primary[lang].title}</h2>
        <div className={classNameTertiary}>
          {primary[lang].content.map((pes) => (
            <p dangerouslySetInnerHTML={{ __html: pes }} key={count()}></p>
          ))}
        </div>
      </div>
    ));

    return item;
  }

  const createNav = (actual) => {
    let content;
    if (language === "EN") {
      if (actual === "webdev") {
        content = "Web dev";
      } else if (actual === "it") {
        content = "IT";
      } else if (actual === "electronics") {
        content = "Electronics";
      } else if (actual === "projects") {
        content = "Projects";
      }else if(actual === "aboutme"){
        content ="About me"
      }
    } else {
      if (actual === "webdev") {
        content = "Desarrollo web";
      } else if (actual === "it") {
        content = "IT";
      } else if (actual === "electronics") {
        content = "Electronica";
      } else if (actual === "projects") {
        content = "Proyectos";
      }else if(actual === "aboutme"){
        content ="acerca de mi"
      }
    }
    return (
      <div className="noselect">
        <h2>{content}</h2>
      </div>
    );
  };

  return (
    <div id="start" className="container">
       <Footer/>
      <PreNav
        createContent={createContent}
        scrollTo={scrollTo}
        language={language}
      />
      <NavBar
        createContent={createContent}
        language={language}
        scrollTo={scrollTo}
        setLanguage={setLanguage}
      />
      <AboutMe createNav={createNav} createContent={createContent} language={language} scrollTo={scrollTo}/>

      <WebDev
        createNav={createNav}
        createContent={createContent}
        language={language}
      />

      <It
        createNav={createNav}
        createContent={createContent}
        language={language}
      />

      <Electronics
        createNav={createNav}
        createContent={createContent}
        language={language}
      />
      <Projects language={language} scrollTo={scrollTo} createNav={createNav} />
     
    </div>
  );
};

export default Start;
