import React, { useState } from "react";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

import image1 from "../../imgs/asignacionesproj/asignaciones.png";
import image2 from "../../imgs/asignacionesproj/conteo.jpeg";
import image3 from "../../imgs/asignacionesproj/graficas.png";
import image4 from "../../imgs/asignacionesproj/sueldo.png";

export default function Asignaciones(props) {
  const [lightBoxAsig, setLightBoxAsig] = useState(false);
  const imagesAsig = [
    { url: image1, title: "Asignaciones" },
    { url: image2, title: "Conteo" },
    { url: image3, title: "Graficas" },
    { url: image4, title: "Sueldo aproximado" },
  ];
  const openLightBoxPicturesAsig = () => {
    setLightBoxAsig(!lightBoxAsig);
  };
  let translation = [
    <div className="secondary">
      <h2>Asignaciones.com.ar</h2>
      <div className="tertiary">
        <h3>
          Pagina web encargada de mostrar horarios de trabajo.
        </h3>
        <br/>
        <p>
          Mejore este sistema poco a poco, agregándole funcionalidad y
          estabilidad.
        </p>
        <p>
          Comencé a guardar los datos de los usuarios en una base de datos, de
          esta manera si se cae el servidor de mi empresa (que pasa bastante
          seguido) podemos seguir viendo nuestros horarios.
        </p>
        <p>Opciones agregadas al sistema inicial:</p>
        <ul>
          <li>
            Francos: Los francos ahora se representan en gráfica como "OFF" en
            en vez de no mostrar el dia.
          </li>
          <li>Horas diarias: Cantidad de horas asignadas cada día.</li>
          <li>Conteo de horas: Cantidad de horas trabajadas por quincena.</li>
          <li>
            Gráfica: Listado de personas que trabajan determinado día, con sus
            respectivos horarios.
          </li>
        </ul>
        <div style={{ display: "none" }}>
          <p>
            Luego de dos años más o menos, volví a crear la página, desde cero
            pasando de un front end PHP con toques de javascript a javascript
            puro.
            <br />
            Pasado un tiempo de haber estado trabajando con javascript puro y
            haciendo la página una SPA me di cuenta que el código estaba
            volviéndose engorroso.
            <br />
            Por esto decidí migrar el front end a React, para así poder
            facilitar el mantenimiento.
          </p>
        </div>
        <h3 className="d-inline">Imagenes:</h3>
        <button className="goToProject " onClick={() => openLightBoxPicturesAsig()}>
          {" "}
          Abrir galeria
        </button>
        {lightBoxAsig && (
          <Lightbox
            images={imagesAsig}
            title="Image Title"
            onClose={() => openLightBoxPicturesAsig()}
            allowRotate={false}

          ></Lightbox>
        )}

        <p>En esta SPA implementé:</p>
        <ul>
          <li>SQL databases</li>
          <li>PHP back end</li>
          <li>React framework</li>
          <li>Sass/css</li>
        </ul>
      </div>
    </div>,
    <div className="secondary">
      <h2>Asignaciones.com.ar</h2>
      <div className="tertiary">
        <p>
          I've been improving this system little by little, adding functionality
          and stability.
        </p>
        <p>
          I started to store each co-worker work schedules, then when my company
          servers went down (this happened very often) we could still watch our
          schedules.
        </p>
        <p>Options added to the company system:</p>
        <ul>
          <li>
            Off days: Off days now are showed on the grafics system with "OFF"
            keyword instead of not showin the off day.
          </li>
          <li>Daily hours: Number of working hours per day.</li>
          <li>Hour count: Number of working hours per fortnight.</li>
          <li>
            Grafics: List of people who work a given day. and their respective
            schedules.
          </li>
        </ul>
        <div style={{ display: "none" }}>
          <p>
            After two years working with html, php and a little bit of
            javascript I started to build the page from scratch, font end with
            pure javascript and re-doing the php backend{" "}
          </p>
          <p>
            After a while working with pure javascript and turning the webpage
            to a SPA i realize that the code was really hard to read
          </p>
          <p>
            Then i decide to migrate all my front end to React for better
            maintenance.
          </p>
        </div>
        <h3 className="d-inline">Images:</h3>
        <button className="goToProject " onClick={() => openLightBoxPicturesAsig()}>
          {" "}
          Open gallery
        </button>
        {lightBoxAsig && (
          <Lightbox
            images={imagesAsig}
            onClose={() => openLightBoxPicturesAsig()}
            allowRotate={false}

          ></Lightbox>
        )}
        <p>In this SPA I implemented:</p>
        <ul>
          <li>SQL databases</li>
          <li>PHP back end</li>
          <li>react framework</li>
          <li>Sass/css</li>
        </ul>
      </div>
    </div>,
  ];
  let display;

  if (props.language === "EN") {
    display = translation[1];
  } else {
    display = translation[0];
  }
  return (
    <div className="project-container" id="asignaciones">
      {display}
    </div>
  );
}
