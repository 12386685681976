import React from "react";

export default function BlogList() {
  let arrayblogs = [
    {
      title: "Arduino",
      subtitle: "Lorem ipsum dolor sit amet.",
      content:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur similique nesciunt iure quae? Perspiciatis vel repellat reiciendis sint veritatis voluptatibus dolor fugiat sequi eaque accusamus ea cumque, et harum cupiditate quae.",
    },
    {
      title: "Raspberry pi 4",
      subtitle: "Lorem ipsum dolor sit amet.",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam commodi culpa quam aperiam labore ut, eos sequi assumenda iure consectetur porro est magni provident odit iusto cumque. Officia, suscipit dolorem.",
    },
    { title: "testo1", subtitle: "Lorem ipsum dolor sit amet.", content: "blablablablablablablabla" },
    { title: "testo2", subtitle: "Lorem ipsum dolor sit amet.", content: "blablablablablablablabla" },
  ];
  return (
    <div className="">
      {arrayblogs.map((item) => {
        return (
          <div key={item.title} className="content h-auto">
            <div className="blog-primary">
              <h2 className="blog-titles">{item.title}</h2>
              <small className="blog-subtitles">{item.subtitle}</small>
              <p className="mw-66">{item.content}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
