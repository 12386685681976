import React from 'react'

export default function Downloads() {
    return (
        <div className="primary">
        <div className="title p-1">
        <div className=" title-container">
            <a className="navButton" href="http://181.31.113.204:8082/Adobe Photoshop 2020 v21.0.1.47 Multilanguage.zip">Photoshop</a>
        </div>
        </div>
        </div>
    )
}
