import React from "react";

export default function Electronics(props) {
  let selection = [
    {
      id: 0,
      EN: {
        title: "General",
        content: [
          "Multimeter usage for reading and troubleshooting.",
          "Usage of soldering iron.",
          "Component replacement on PCB.",
        ],
      },
      ES: {
        title: "General",
        content: [
          "Uso de multímetro para lectura y solución de problemas.",
          "Soldado con estaño.",
          "Reemplazo de componentes en PCB.",
        ],
      },
    },
    {
      EN: {
        title: "Arduino",
        content: [
          "Basic programming (c++) for arduino control.",
          "Modules connection.",
          "Data reading from pins.",
        ],
      },
      ES: {
        title: "Arduino",
        content: [
          "Programación básica (C++) para control de Arduino.",
          "Conexión de módulos.",
          "Lectura de datos desde pines.",
        ],
      },
      id: 1,
    },
  ];

  return (
    <div id="electronics" className="content">
      <div className="title">
        <div className="title-container">{props.createNav("electronics")}</div>
      </div>
      <div className="primary">
        <div className="cont">{props.createContent(selection)}</div>
      </div>
      <div className="under"></div>
    </div>
  );
}
