import React from 'react'

export default function RedBookshelf(props) {

    let translation = [
        <div className="secondary">
          <h2>Sistema de iluminación RedBookshelf</h2>
          <div className="tertiary">
            <h3>
              RedBookshelf es un sistema de iluminación automatizado para plantas de interior.
            </h3>   
            <p>Este proyecto fue construido por la necesidad de cultivar plantas carnívoras en el interior de una casa.</p>
            <p>Se implementó Arduino con sensores de luminosidad y relés, para controlar luces y bombas de agua.</p>
            <p>El sistema tiene controles manuales para saltear el sistema automatizado si es necesario.</p>
          </div>
        </div>,
        <div className="secondary">
          <h2>RedBookshelf light system</h2>
          <div className="tertiary">
            <h3>
              RedBookshelf is an interior plant automatic lighting system.
            </h3>
           <p>This project was build for the necessity of cultivating carnivorous plants indoor.</p>
           <p>It implements arduino as a micro controller, with light sensors and relays to control light and pumps.</p>
           <p>This system has manual controls that override the automatic behavior if needed.</p>
          </div>
        </div>,
      ];
      let display;
    
      if (props.language === "EN") {
        display = translation[1];
      } else {
        display = translation[0];
      }
      return <div id="redBookshelf">{display}</div>;


}
